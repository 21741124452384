.page {
    margin: 1em;
}

.center {
    text-align: center;
}

@media screen and (max-width: 1200px) {
  .header-button-text {
    display: none;
  }
}

body {
  //background-image: url("/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
}