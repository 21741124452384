.imagetable td {
  padding: 0.2rem;
}

.imagetable  tbody tr:nth-child(odd) {
  background-color: #F6F7F9;
  color: #111418;
}

.imagetable  tbody tr:nth-child(even) {
  background-color: #E5E8EB;
  color: #111418;
}

