.lightbox-container {
    background-color: black;
}

.product_image_container {
    overflow: hidden;
}

.product_image {
    width: 80%;
    height: auto;
    text-align: center;
    vertical-align: middle;
    transition: transform .5s ease;

    background-image: linear-gradient(45deg, #808080 25%, transparent 25%), linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%), linear-gradient(-45deg, transparent 75%, #808080 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;    
}

.product_image:hover {
    transform: scale(1.5);
}

.product_image_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 98%;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 0.2rem;
    margin: 0.1rem;
}

.product {
    border: 1px dashed;
    border-color: #808080;
    border-radius: 5px;
}

.product:hover {
    background-color: #eeeeee;
}